<template lang="pug">
    section#timeline
        TimelineHorizontal(v-if="specialType == 'horizontal'", :contents="contents", @setContent="setContent")
        TimelineVertical(v-if="specialType == 'vertical'", :contents="contents", @setContent="setContent")
        TimelineContent(v-if="timelineContent", :contents="timelineContent", @setContent="setContent")
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'section-timeline',
  props,
  components,
  data () {
    return {
      timelineContent: null
    }
  },
  methods: {
    setContent (content) {
      this.timelineContent = content
    }
  }
}
</script>

<style lang="stylus" scoped src="./Timeline.styl"></style>
